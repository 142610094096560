import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import './form.scss';

const MAIL_URL = `https://admin.precisionappliancerepairpro.com/wp-json/contact-form-7/v1/contact-forms/8/feedback`;

const BookingForm = ({ services, preSelect }) => {
  const [service, setService] = useState();

  const navigate = useNavigate();

  const submit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    data.append('_wpcf7_unit_tag', true);

    try {
      const response = await axios.post(MAIL_URL, data);

      if (response.status === 200) {
        toast.success(response.data.message);
        event.target.reset();
        navigate('/thank-you');
      }
    } catch (error) {
      toast.error('Ooops! Something went wrong! Please, try again!');
      console.log(error);
    }
  };

  useEffect(() => {
    switch (preSelect) {
      case 'dishwasher-repair':
        setService('Dishwasher Repair');
        break;
      case 'dryer-repair':
        setService('Dryer Repair');
        break;
      case 'freezer-repair':
        setService('Freezer Repair');
        break;
      case 'microwave-repair':
        setService('Microwave Repair');
        break;
      case 'oven-repair':
        setService('Oven Repair');
        break;
      case 'stove-repair':
        setService('Stove Repair');
        break;
      case 'refrigerator-repair':
        setService('Refrigerator Repair');
        break;
      case 'washer-repair':
        setService('Washer Repair');
        break;
      default:
        setService('');
        break;
    }
  }, [preSelect]);

  return (
    <div>
      <form onSubmit={submit} className="booking-form">
        <input required type="text" name="first_name" placeholder="First Name *" />
        <input required type="text" name="last_name" placeholder="Last Name *" />
        <input required type="email" name="email" placeholder="Email *" />
        <input required type="tel" name="phone" placeholder="Phone *" />
        <select
          required
          name="type_of_service"
          value={service}
          defaultValue={service}
          onChange={(event) => setService(event.target.value)}
        >
          <option hidden>Type of service *</option>
          {services?.reverse().map((el, index) => (
            <option key={index} value={el.title}>
              {el.title}
            </option>
          ))}
        </select>
        <textarea required name="message" placeholder="Please let us know if you have any special requests *" />

        <input type="submit" value="Submit" className="btn contained" />
      </form>

      <div className="data-protection-label">
        <svg aria-hidden="true" viewBox="0 0 24 24">
          <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
        </svg>

        <p>Data protected and privacy</p>
      </div>
    </div>
  );
};

export default BookingForm;
