import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useFetch from 'react-fetch-hook';

import { Spinner } from './components';

import PageTemplate from './pages/PageTemplate';
import LegalPageTemplate from './pages/LegalPageTemplate';
import BookNow from './pages/BookNow';
import PageNotFound from './pages/PageNotFound';
import ThankYou from './pages/ThankYou';

export const ADMIN_URL = 'https://admin.precisionappliancerepairpro.com/wp-json/wp/v2';

const App = () => {
  const { isLoading, data, error } = useFetch(`${ADMIN_URL}/pages?per_page=100`);

  const settings = useFetch(`${ADMIN_URL}/general_settings?slug=settings`);

  useEffect(() => {
    if (error || settings.error) toast.error('Data loading error. Please reload the page!');
  }, [error, settings.error]);

  if (isLoading || settings.isLoading) return <Spinner />;

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/appliance-repair" />} />

      {data?.map((page) => (
        <Route
          key={page.id}
          path={`/${page.slug}`}
          element={
            <PageTemplate
              seo={page.acf.seo.main}
              title={page.title.rendered}
              about={{
                image: page.acf.images.main.about_section,
                content: page.acf.content.main,
              }}
              contacts={{
                email: settings.data[0].acf.email,
                phone: settings.data[0].acf.phones.main,
              }}
              services={data
                ?.filter((el) => el.title.rendered !== 'Appliance Repair')
                .map(
                  (el) =>
                    [
                      {
                        title: String(el.title.rendered),
                        slug: String(el.slug),
                        icon: el.acf.images.main.icon,
                      },
                    ][0],
                )}
              reviews={settings.data[0].acf.reviews.main}
              areas={settings.data[0].acf.areas.main}
              steps={settings.data[0].acf.how_to_get_service}
              brands={{
                logos: settings.data[0].acf.brands,
                description: settings.data[0].acf.brands_description,
              }}
              why_we={settings.data[0].acf.why_we_content}
              logos={{
                header: settings.data[0].acf.header_logo,
                footer: settings.data[0].acf.footer_logo,
              }}
            />
          }
        />
      ))}

      <Route
        path="/book-now"
        element={
          <BookNow
            title="Book Now"
            data={settings.data[0].acf.privacy_policy}
            contacts={{
              email: settings.data[0].acf.email,
              phone: settings.data[0].acf.phones.main,
            }}
            logos={{
              header: settings.data[0].acf.header_logo,
              footer: settings.data[0].acf.footer_logo,
            }}
            services={data
              ?.filter((el) => el.title.rendered !== 'Appliance Repair')
              .map(
                (el) =>
                  [
                    {
                      title: String(el.title.rendered),
                      slug: String(el.slug),
                      icon: el.acf.icon,
                    },
                  ][0],
              )}
          />
        }
      />

      <Route
        path="/privacy-policy"
        element={
          <LegalPageTemplate
            // seo={page.acf.seo}
            title="Privacy Policy"
            data={settings.data[0].acf.privacy_policy}
            contacts={{
              email: settings.data[0].acf.email,
              phone: settings.data[0].acf.phones.main,
            }}
            logos={{
              header: settings.data[0].acf.header_logo,
              footer: settings.data[0].acf.footer_logo,
            }}
          />
        }
      />

      <Route
        path="/terms-of-use"
        element={
          <LegalPageTemplate
            // seo={page.acf.seo}
            title="Terms Of Use"
            data={settings.data[0].acf.terms_of_use}
            contacts={{
              email: settings.data[0].acf.email,
              phone: settings.data[0].acf.phones.main,
            }}
            logos={{
              header: settings.data[0].acf.header_logo,
              footer: settings.data[0].acf.footer_logo,
            }}
          />
        }
      />

      <Route
        path="/accessibility-statement"
        element={
          <LegalPageTemplate
            // seo={page.acf.seo}
            title="Accessibility Statement"
            data={settings.data[0].acf.accessibility_statement}
            contacts={{
              email: settings.data[0].acf.email,
              phone: settings.data[0].acf.phones.main,
            }}
            logos={{
              header: settings.data[0].acf.header_logo,
              footer: settings.data[0].acf.footer_logo,
            }}
          />
        }
      />

      <Route
        path="/404"
        element={
          <PageNotFound
            // seo={page.acf.seo}
            title="Error 404 - Page Not Found"
            contacts={{
              email: settings.data[0].acf.email,
              phone: settings.data[0].acf.phones.main,
            }}
            logos={{
              header: settings.data[0].acf.header_logo,
              footer: settings.data[0].acf.footer_logo,
            }}
          />
        }
      />

      <Route
        path="/thank-you"
        element={
          <ThankYou
            // seo={page.acf.seo}
            title="Thank You"
            contacts={{
              email: settings.data[0].acf.email,
              phone: settings.data[0].acf.phones.main,
            }}
            logos={{
              header: settings.data[0].acf.header_logo,
              footer: settings.data[0].acf.footer_logo,
            }}
          />
        }
      />

      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};

export default App;
